@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_content {
    margin: 16px 0 40px;

    @include breakpoint(xxlarge) {
        margin: 24px 0 80px;
    }

    .section-glow {
        &.big {
            bottom: 86px;

            @include breakpoint(xlarge down) {
                right: 0;
            }
            @include breakpoint(xxlarge) {
                bottom: -100px;
                left: -330px;
            }
        }

        &.small {
            @include breakpoint(xlarge down) {
                top: 0;
                left: -180px;
            }

            @include breakpoint(xxlarge) {
                right: 0;
                bottom: 0;
            }
        }
    }

    .animate-title {
        display: flex;
        align-items: flex-start;

        .icon {
            width: 60px;
            height: 60px;
            margin-right: 14px;

            @include breakpoint(xxlarge) {
                width: 120px;
                height: 120px;
                margin-right: 28px;
            }
        }

        .title {
            position: relative;
            z-index: 5;
            margin-bottom: -7%;

            @include breakpoint(small only) {
                padding-right: 12px;
                padding-left: 12px;
            }

            @include breakpoint(xxlarge) {
                margin-bottom: -14%;
            }
        }
    }

    figure,
    video {
        aspect-ratio: 4 / 3;
        position: relative;
        display: block;

        @include breakpoint(xxlarge) {
            aspect-ratio: 9 / 10;
        }
    }

    @include breakpoint(small only) {
        figure {
            padding-bottom: 75%;
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .play-button {
        transform: none;
        top: unset;
        left: 48px;
        z-index: 5;

        @include breakpoint(xlarge down) {
            bottom: 12px;
        }

        @include breakpoint(xxlarge) {
            top: 75%;
            left: 24px;
        }
    }

    .image {
        position: relative;

        figure img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .buttons {
        margin-top: 8px;
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
            margin-top: 28px;
            align-items: flex-start;
        }

        .button {
            margin: 8px;
        }
    }

    .text {
        @include breakpoint(xlarge down) {
            margin-top: 24px;
        }

        @include breakpoint(xxlarge) {
            padding-top: 200px;
        }
    }

    @include breakpoint(xlarge down) {
        .animate-media {
            padding: 0 32px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:2112";