@import "../../../src/scss/themes/tergos/abstracts/custom_variables";
@import "../../../src/scss/themes/tergos/abstracts/custom_mixins";
@import "_default/block_hero_content.scss";

.block_hero_content {
    p {
        @include font-text-l;
        letter-spacing: -0.36px;

        @include breakpoint(xlarge) {
            letter-spacing: -0.64px;
            line-height: 125%;
        }
    }

    figure:before,
    figure:after,
    .image.has_overlay:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: block;
    }

    .has-video:before,
    .has-video:after {
        content: '';
        width: calc(100% - 64px);
        height: 100%;
        top: 0;
        left: 32px;
        position: absolute;
        display: block;

        @include breakpoint(xxlarge) {
            width: 100%;
            left: 0;
        }
    }

    .has-video:before {
        background: $color-overlay-multiply;
        mix-blend-mode: multiply;
        z-index: 2;
    }

    .has-video:after {
        background: $color-overlay-difference;
        mix-blend-mode: difference;
        z-index: 3;
    }

    figure:before {
        background: $color-overlay-gradient-multiply;
        mix-blend-mode: multiply;
        z-index: 2;
    }

    figure:after {
        background: $color-overlay-gradient-difference;
        mix-blend-mode: difference;
        z-index: 3;
    }

    .image.has_overlay:before {
        background: $color-overlay-color;
        mix-blend-mode: overlay;
        z-index: 4;
    }
}
